@import '../../../../scss/_theme-bootstrap';

.content-block-sitewide-banner {
  width: 100%;
  display: block;
  position: relative;
  height: 0;
  transition: all 0.333s ease;
  transform-origin: center center;
  color: $color-off-black;
  z-index: 6;
  overflow: visible;
  .content-block-sitewide-banner--enabled & {
    height: $content-block-sitewide-banner-height;
    @include breakpoint($portrait-up) {
      height: $content-block-sitewide-banner-height-desktop;
    }
  }
  .site-header:not(.hide-site-header-on-scroll) & {
    .content-block-sitewide-banner--hidden & {
      height: 0;
      overflow: hidden;
    }
  }
  &__content-wrapper {
    height: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.justify-start {
      padding-#{$ldirection}: 20px;
    }
    &.justify-end {
      padding-#{$rdirection}: 20px;
    }
    @include breakpoint($portrait-up) {
      flex-direction: row;
    }
  }
  &__message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-#{$rdirection}: 20px;
    padding: 5px 15px;
    @include breakpoint($portrait-up) {
      margin-#{$ldirection}: 20px;
      padding: 0;
    }
    .content-block-sitewide-banner--light & {
      color: $color-white;
      a {
        color: $color-white;
        @if $hit_area_update == true {
          display: inline-block;
          margin: 6px 0 0;
          line-height: normal;
          min-height: 24px;
          @include breakpoint($portrait-up) {
            min-height: unset;
            margin: 0;
          }
        }
      }
    }
  }
  &__content,
  &__content-signup {
    position: relative;
    z-index: 6;
  }
  &__trigger {
    display: block;
    position: absolute;
    top: 10px;
    #{$rdirection}: 10px;
    z-index: 1;
    cursor: pointer;
    -webkit-appearance: none;
    background: transparent;
    border-color: transparent;
    padding: 0;
    border-width: 1px;
    background: transparent;
    min-width: auto;
    @if $hit_area_update == true {
      width: 24px;
      height: 24px;
    }
    @include breakpoint($portrait-up) {
      top: 50%;
      #{$rdirection}: 20px;
      transform: translateY(-50%);
    }
    svg {
      display: block;
    }
    &[aria-expanded='false'] {
      display: none;
      & + .content-block-sitewide-banner__content-wrapper {
        display: none;
      }
    }
    &:hover,
    &:focus {
      background: transparent;
    }
    .icon--caret--close {
      width: 15px;
      height: 15px;
      stroke: $color-off-black;
      stroke-width: 50;
      .content-block-sitewide-banner--light & {
        stroke: $color-white;
        @if $hit_area_update == true {
          margin: 0 auto;
        }
      }
    }
  }
  &__background-image {
    position: absolute;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-block__link {
    font-size: 13px;
    line-height: 1;
    padding: 0;
  }
  &__rule-line {
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .content-block__line {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1;
    @include breakpoint($portrait-up) {
      line-height: 1.5;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .content-block--button {
    line-height: 1;
    button {
      @if $hit_area_update == true {
        line-height: normal;
        min-height: 24px;
        margin: 5px 0 0;
        @include breakpoint($portrait-up) {
          min-height: unset;
          margin: 0;
        }
      }
    }
  }
  p {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  .mantle-media-asset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img,
    picture,
    video {
      width: 100%;
      max-width: unset;
    }
  }
}

.site-header__utility-desktop {
  &.header-mobile-hidden {
    .content-block-sitewide-banner--enabled & {
      @include breakpoint($portrait-up) {
        transition: top 0.333s ease;
      }
    }
    .content-block-sitewide-banner--enabled.site-header.hide-site-header-on-scroll & {
      @include breakpoint($portrait-up) {
        transition: none;
        top: 155px;
      }
    }
    body:not(.content-block-sitewide-banner--hidden) .content-block-sitewide-banner--enabled & {
      @include breakpoint($portrait-up) {
        top: 155px;
      }
    }
  }
}
